// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-shop-bmw-bluetooth-adapter-js": () => import("./../../../src/pages/shop/bmw-bluetooth-adapter.js" /* webpackChunkName: "component---src-pages-shop-bmw-bluetooth-adapter-js" */),
  "component---src-pages-shop-faq-js": () => import("./../../../src/pages/shop/faq.js" /* webpackChunkName: "component---src-pages-shop-faq-js" */),
  "component---src-site-index-js": () => import("./../../../src/site/index.js" /* webpackChunkName: "component---src-site-index-js" */),
  "component---src-site-logs-0-x-001-first-pcb-design-js": () => import("./../../../src/site/logs/0x001/first-pcb-design.js" /* webpackChunkName: "component---src-site-logs-0-x-001-first-pcb-design-js" */),
  "component---src-site-logs-0-x-001-from-idea-to-prototype-js": () => import("./../../../src/site/logs/0x001/from-idea-to-prototype.js" /* webpackChunkName: "component---src-site-logs-0-x-001-from-idea-to-prototype-js" */),
  "component---src-site-logs-0-x-001-reducing-noise-js": () => import("./../../../src/site/logs/0x001/reducing-noise.js" /* webpackChunkName: "component---src-site-logs-0-x-001-reducing-noise-js" */),
  "component---src-site-logs-0-x-001-testing-the-second-board-js": () => import("./../../../src/site/logs/0x001/testing-the-second-board.js" /* webpackChunkName: "component---src-site-logs-0-x-001-testing-the-second-board-js" */),
  "component---src-site-logs-0-x-001-the-final-hardware-js": () => import("./../../../src/site/logs/0x001/the-final-hardware.js" /* webpackChunkName: "component---src-site-logs-0-x-001-the-final-hardware-js" */),
  "component---src-site-logs-0-x-001-wrapping-up-js": () => import("./../../../src/site/logs/0x001/wrapping-up.js" /* webpackChunkName: "component---src-site-logs-0-x-001-wrapping-up-js" */),
  "component---src-site-logs-0-x-002-bldc-introduction-js": () => import("./../../../src/site/logs/0x002/bldc-introduction.js" /* webpackChunkName: "component---src-site-logs-0-x-002-bldc-introduction-js" */),
  "component---src-site-logs-0-x-002-block-commutation-js": () => import("./../../../src/site/logs/0x002/block-commutation.js" /* webpackChunkName: "component---src-site-logs-0-x-002-block-commutation-js" */),
  "component---src-site-logs-0-x-002-component-selection-js": () => import("./../../../src/site/logs/0x002/component-selection.js" /* webpackChunkName: "component---src-site-logs-0-x-002-component-selection-js" */),
  "component---src-site-logs-0-x-002-hardware-design-js": () => import("./../../../src/site/logs/0x002/hardware-design.js" /* webpackChunkName: "component---src-site-logs-0-x-002-hardware-design-js" */),
  "component---src-site-logs-0-x-003-clock-design-js": () => import("./../../../src/site/logs/0x003/clock-design.js" /* webpackChunkName: "component---src-site-logs-0-x-003-clock-design-js" */),
  "component---src-site-logs-0-x-003-display-modes-js": () => import("./../../../src/site/logs/0x003/display-modes.js" /* webpackChunkName: "component---src-site-logs-0-x-003-display-modes-js" */),
  "component---src-site-logs-0-x-003-housing-and-assembly-js": () => import("./../../../src/site/logs/0x003/housing-and-assembly.js" /* webpackChunkName: "component---src-site-logs-0-x-003-housing-and-assembly-js" */),
  "component---src-site-logs-0-x-003-let-there-be-light-js": () => import("./../../../src/site/logs/0x003/let-there-be-light.js" /* webpackChunkName: "component---src-site-logs-0-x-003-let-there-be-light-js" */),
  "component---src-site-logs-0-x-003-redesign-and-pcb-js": () => import("./../../../src/site/logs/0x003/redesign-and-pcb.js" /* webpackChunkName: "component---src-site-logs-0-x-003-redesign-and-pcb-js" */),
  "component---src-site-logs-0-x-004-configuration-manager-js": () => import("./../../../src/site/logs/0x004/configuration-manager.js" /* webpackChunkName: "component---src-site-logs-0-x-004-configuration-manager-js" */),
  "component---src-site-logs-0-x-004-framework-introduction-js": () => import("./../../../src/site/logs/0x004/framework-introduction.js" /* webpackChunkName: "component---src-site-logs-0-x-004-framework-introduction-js" */),
  "component---src-site-logs-0-x-004-https-requests-js": () => import("./../../../src/site/logs/0x004/https-requests.js" /* webpackChunkName: "component---src-site-logs-0-x-004-https-requests-js" */),
  "component---src-site-logs-0-x-004-web-server-js": () => import("./../../../src/site/logs/0x004/web-server.js" /* webpackChunkName: "component---src-site-logs-0-x-004-web-server-js" */),
  "component---src-site-logs-0-x-004-wifi-manager-js": () => import("./../../../src/site/logs/0x004/wifi-manager.js" /* webpackChunkName: "component---src-site-logs-0-x-004-wifi-manager-js" */),
  "component---src-site-logs-0-x-005-hardware-design-js": () => import("./../../../src/site/logs/0x005/hardware-design.js" /* webpackChunkName: "component---src-site-logs-0-x-005-hardware-design-js" */),
  "component---src-site-logs-0-x-005-logging-to-the-cloud-js": () => import("./../../../src/site/logs/0x005/logging-to-the-cloud.js" /* webpackChunkName: "component---src-site-logs-0-x-005-logging-to-the-cloud-js" */),
  "component---src-site-logs-0-x-005-testing-the-sensor-js": () => import("./../../../src/site/logs/0x005/testing-the-sensor.js" /* webpackChunkName: "component---src-site-logs-0-x-005-testing-the-sensor-js" */),
  "component---src-site-logs-0-x-005-using-deep-sleep-js": () => import("./../../../src/site/logs/0x005/using-deep-sleep.js" /* webpackChunkName: "component---src-site-logs-0-x-005-using-deep-sleep-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

